import {useDispatch} from "react-redux";
import {useEffect, useRef} from "react";
import {AppDispatch, AppState} from "../../store";
import {useAppSelector} from "../index";
import {fetchUserChats, selectUserChats} from "../../store/feature/chat";
import {selectStudentsDetails} from "../../store/feature/user";
import {useParams} from "react-router";

export const useFetchChats = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {activeStudentId, language} = useAppSelector((state: AppState) => state.configuration) ;
    const studentData = useAppSelector((state) => selectStudentsDetails(state));
    const { chatId  } = useParams();

    const prevDeps = useRef({ chatId, activeStudentId, studentData, language });



    useEffect(() => {
        // @ts-ignore
        const changedDeps = Object.entries({ chatId, activeStudentId, studentData, language }).filter(([key, value]) => prevDeps.current[key] !== value)
            .map(([key]) => key);
        prevDeps.current = {  chatId, activeStudentId, studentData, language  };
        if (changedDeps.length === 1 && changedDeps[0] === 'chatId' && chatId) {
            return
        }

        dispatch(fetchUserChats({studentId: activeStudentId, language}));
    }, [chatId, activeStudentId, studentData, language]);

    return useAppSelector((state) => selectUserChats(state));
}
