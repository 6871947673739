import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {fetchStudentsDetails, selectStudentsDetails} from "../../store/feature/user";
import {AppDispatch} from "../../store";
import {useAppSelector} from "../index";

export const useFetchStudentsDetails = () => {
    const dispatch = useDispatch<AppDispatch>();
    const existingValue = useAppSelector((state) => selectStudentsDetails(state));

    useEffect(() => {
        if (!existingValue) {
            dispatch(fetchStudentsDetails());
        }
    }, [dispatch, existingValue]);

    return useAppSelector((state) => selectStudentsDetails(state));
}
