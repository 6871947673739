import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Box} from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import {StudentProfileSection} from "./StudentProfileSection";
import React from "react";
import {PageTitle} from "../../../ui-component/widget/PageTitle";
import {DesktopMenu} from "../../../ui-component/widget/DesktopMenu";
import {useAppSelector} from "../../../hooks";

// assets

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();

  const { showTitle } = useAppSelector((state) => state.customize)


  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 150,
          display: { xs: 'none', lg: 'flex' },
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{flexGrow: 1 }}>
          <LogoSection />
        </Box>

      </Box>

      {showTitle && <PageTitle/>}

        <Box component="div" sx={{ display: { xs: 'none', sm: 'block' }, flexGrow: 1 }}>
            <DesktopMenu/>
        </Box>

        <Box sx={{flexGrow: 1}} />

      {/* notification & profile */}
      <StudentProfileSection variant={'full'}/>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
