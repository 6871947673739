import {Avatar, Box, Chip, Divider, Stack, Typography} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import CampaignIcon from "@mui/icons-material/Campaign";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {NotificationBadge} from "../../NotificationBadge";
import {EventMessage, SchoolEvent} from "../../../store/feature/event";
import {stringAvatar} from "../../../utils/colours";

type Props = {
    event: SchoolEvent<EventMessage>
}

export const AnnounceListView = ({event} : Props) => {

    const theme = useTheme();
    const [month, day, year] = dayjs(event.createdOn).format('MMM D YYYY').split(' ');
    const {t} = useTranslation();

    return (
        <Box>
            <Stack direction={'row'} spacing={3} sx={{mb:2}} alignItems={'center'}>
                <Avatar {...stringAvatar(event.sender)}/>
                <Typography sx={{fontSize: '1.15rem', fontWeight: '500', paddingBottom: '5px', width:'100%', mr:1}}  variant={'h5'}>{event.subject}</Typography>
                <NotificationBadge visible={!event.read}/>
            </Stack>

            <Stack sx={{width: '100%', marginBottom: '7px'}} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                <Chip label={t('event.announcement.name')} sx={{textTransform: 'capitalize', color: '#4ba7f9', fontWeight:600, backgroundColor: 'white', border: '1px solid #4ba7f9', '& svg': {color: '#4ba7f9'}}} icon={<CampaignIcon color={'action'}/>}></Chip>

                <Box>
                    <Typography sx={{fontSize: '0.7rem', fontWeight: '600'}}  variant={'body1'}>{event.sender}</Typography>
                    <Typography sx={{fontSize: '0.7rem', fontWeight: '500', color: '#7d8491'}}  variant={'body1'}>{`${t(month)} ${day} ${year}`}</Typography>
                </Box>

            </Stack>

            <Divider sx={{margin: '20px 0'}}/>

            <Typography variant={'body2'} dangerouslySetInnerHTML={{__html: event.event.message.length > 300 ? event.event.message.substring(0, 300) + '... <span style="color:'+theme.palette.primary.main+'">Read More</span>'  : event.event.message}}></Typography>
        </Box>
    )
}
