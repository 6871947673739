import {
    Avatar,
    Box,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {avatarProps} from "../../../utils/colours";
import {getPrettyDate} from "../../../utils/dates";
import {NotificationBadge} from "../../NotificationBadge";
import React, {useCallback} from "react";
import {Chat} from "../../../types";
import {useTheme} from "@mui/material/styles";

type Props = {
    chat: Chat,
    onChatClick: (chat: Chat) => void,
    avatar?: string,
    isLast: boolean
}

export const ChatListItem = ({chat, onChatClick, isLast, avatar}: Props) => {

    const theme = useTheme();

    const getChatListBackgroundColour = useCallback((chat: Chat) => {

        if (!chat.read) {
            return theme.palette.primary.light;
        }
        return 'white';
    }, [chat])

    return (<Box key={chat.chatId} sx={{m:0, p:0, backgroundColor: getChatListBackgroundColour(chat)}}>
        <ListItem sx={{p:0, minHeight: '80px'}} >
            <ListItemButton alignItems={'flex-start'} onClick={() => onChatClick(chat)}>
                <ListItemAvatar sx={{mt: 0}}>
                    <Avatar {...avatarProps(chat.chatName, 50, avatar)}/>
                </ListItemAvatar>
                <ListItemText sx={{m: 0}}>
                    <Stack direction={'column'}>
                        <Stack direction={'row'}>
                            <Typography variant={'h4'} sx={{fontSize:'0.9rem', color: '#26282d', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{chat.chatName}</Typography>
                            <Box sx={{flexGrow: 1}} />
                            <Box sx={{minWidth: '45px', color: '#5c5c5c'}}>{getPrettyDate(chat.lastMessageTime)}</Box>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Box>
                                <Box sx={{color: '#757575'}}>{chat.message}</Box>
                            </Box>
                            <NotificationBadge sx={{position: 'absolute', right: '20px'}}  visible={!chat.read}/>
                        </Stack>
                    </Stack>
                </ListItemText>
            </ListItemButton>
        </ListItem>
        {!isLast && (<Divider sx={{borderColor: '#babcbf'}} />)}
    </Box>);
}
