import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";

import {ChatWindow} from "../../ui-component/widget/chat/ChatWindow";
import {Box, Stack, Typography} from "@mui/material";
import {Loader} from "../../ui-component/Loader";
import {Chat} from "../../types";
import {useFetchChats} from "../../hooks/fetch/useFetchChats";
import {useNavigate, useParams} from "react-router";
import {
    fetchLatestUserFacilityChatMessages,
    fetchMoreUserFacilityChatMessages,
    selectUserChatMessagesPerChat
} from "../../store/feature/chat";
import {ChatList} from "../../ui-component/widget/chat/ChatList";
import {SET_ACTIVE_CHAT} from "../../store/activeChatReducer";

export const ChatPage = () => {
    const dispatch = useAppDispatch();
    const chats: Chat[] = useFetchChats();
    const navigate = useNavigate();

    const { chatId  } = useParams();
    const handleChatClick = (chat: Chat) => {
        navigate(`/chats/${chat.chatId}`);
    }
    const activeChat = useAppSelector(state => state.activeChat.activeChat);
    const [activeChatLoading, setActiveChatLoading] = useState(false);
    const chatItems = useAppSelector(selectUserChatMessagesPerChat);

    const {activeStudentId, id} = useAppSelector((state) => state.configuration);

    useEffect(() => {
        const chatIdNumber = Number(chatId);
        if (chats && !Number.isNaN(chatIdNumber)) {
            const chat = chats.filter(c => c.chatId === chatIdNumber);
            if (chat.length > 0 && chat[0].chatId !== activeChat?.chatId) {
                dispatch({type: SET_ACTIVE_CHAT, value: chat[0]});
                updateChatMessages(false, chat[0]);
                return;
            }
        } else if(!chatId) {
            dispatch({type: SET_ACTIVE_CHAT, value: null});
        }
    }, [chatId, chats]);

    const updateChatMessages = (silent: boolean, c?: Chat) => {
        const currentChat = c ?? activeChat;
        if (currentChat) {
            if  (!silent) {
                setActiveChatLoading(true)
            }
            dispatch(fetchLatestUserFacilityChatMessages({ chatId: currentChat.chatId, studentId: activeStudentId, userId: id })).then((r) => {
                setActiveChatLoading(false)
                  //dispatch(fetchStudentsDetails());
            } )
        }
    }

    const loadMore = (page: number) => {
        return dispatch(fetchMoreUserFacilityChatMessages({chatId: activeChat!.chatId, studentId: activeStudentId, userId: id, page: page, size: chatItems.pageable.pageSize}))
    }
    // Message input for mobile is in footer
    return (
        <Box sx={{width: '100%', margin: 'auto'}}>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'start'} textAlign={'center'}>
                <Box sx={{
                    width: { xs: '100%', md: '25%'},
                    maxWidth: {xs: '100%', md: '25%'},
                    display: {xs: activeChat ? 'none' : 'block', md: 'block'}
                }}>

                    {!chats && <Loader/>}
                    {chats && (<ChatList sx={{
                        position: {md: 'absolute'},
                        mt: '15px',
                        width: { xs: '100%', md: '25%'},
                        backgroundColor: {md: '#f7f7f7'}
                    }} chats={chats} onChatClick={handleChatClick}/>)}
                </Box>
                <Box sx={{width: {xs: '100%', md: '75%'}, display: {xs: activeChat ? 'block' : 'none', md: 'block'}}}>
                    {!activeChat &&(
                        <Stack sx={{display: {xs: 'none', md:'flex'}, width: {xs: '100%'}}} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                            <Typography variant={'h4'} sx={{fontSize: '2rem', mt: '50px', color: '#676767'}}>No Active chat</Typography>
                        </Stack>
                    )}
                    {activeChat && (
                        <Box sx={{width: '100%'}} style={{marginLeft: 0, backgroundColor: activeChatLoading ? 'ghostwhite' : 'white'}}>
                            {activeChatLoading && <Loader />}
                            {!activeChatLoading && chatItems && <ChatWindow chatId={activeChat.chatId} pageableItems={chatItems} loadMore={loadMore}/>}
                        </Box>
                    )}
                </Box>
            </Stack>
        </Box>
    )
}
