import * as React from 'react';
import {Link, useMatch, useResolvedPath} from "react-router-dom";
import {Badge, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../hooks";
import {selectStudentsDetails} from "../../store/feature/user";


export const DesktopMenu = () => {

    const studentData = useAppSelector((state) => selectStudentsDetails(state));
    const {activeStudentId} = useAppSelector((state) => state.configuration);
    const [showChatBadge, setShowChatBadge] = useState(false);
    const [showEventsBadge, setShowEventsBadge] = useState(false);
    const [showGalleryBadge, setShowGalleryBadge] = useState(false);

    useEffect(() => {
        if(studentData && activeStudentId) {
            studentData.filter((s) => s.id === activeStudentId).forEach((s) => {
                setShowChatBadge(!s.chatRead);
                setShowEventsBadge(!s.eventsRead);
                setShowGalleryBadge(!s.galleriesRead);
            })

        }
    }, [studentData, activeStudentId]);

    const {t} = useTranslation();
    const theme = useTheme();

    return (
        <Stack direction="row" spacing={2} sx={{
            '& a': {
                color: '#969696',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '1.1rem'
                },

                [theme.breakpoints.up('lg')]: {
                    fontSize: '1.5rem'
                },

                fontFamily: 'system-ui',
                fontWeight:700,
                padding:'16px',
                textDecoration:'none',
                cursor:'pointer',
                transition: 'all 0.3s ease',
                '&:hover': {
                    color: theme.palette.primary.main
                },
                '&.active': {
                    color: theme.palette.primary.main
                }
            }
        }}>
            <CustomLink to="/" >{t('menu.calendar')}</CustomLink>
            <Badge variant={'dot'} color={'error'} invisible={!showGalleryBadge}><CustomLink to="/galleries" >{t('menu.galleries')}</CustomLink></Badge>
            <Badge variant={'dot'} color={'error'} invisible={!showChatBadge}><CustomLink to="/chats" >{t('menu.messages')}</CustomLink></Badge>
            <Badge variant={'dot'} color={'error'} invisible={!showEventsBadge}><CustomLink to="/notifications/announcements" >{t('menu.announcements')}</CustomLink></Badge>
        </Stack>
    );
}

type Props = {
    to: string,
    children: React.ReactNode

}

const CustomLink = ({ children, to }: Props) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    const linkClassNames = match ? 'active' : '';
    return (
                <Link
                    to={to}
                    className={linkClassNames}
                >
                    {children}
                </Link>
        );
    };
