import dayjs from "dayjs";

export const getPrettyDate = (date: string | undefined) => {
    if (!date) {
        return '';
    }
    try {
        const dayjsDate = dayjs(date);
        return dayjsDate.isToday() ? dayjsDate.format('HH:mm') : dayjsDate.format('DD MMM');
    } catch (e) {
        console.error(e);
    }

}
