import {Box, InputAdornment, List, ListItem, TextField, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Chat} from "../../../types";
import SearchIcon from '@mui/icons-material/Search';
import {SxProps} from "@mui/system";
import {ChatListItem} from "./ChatListItem";
import {useTranslation} from "react-i18next";

type ChatListprops = {
  chats: Chat[],
  onChatClick: (chat: Chat) => void,
  sx?: SxProps
}

export const ChatList = ({chats, onChatClick, sx}: ChatListprops) => {
  const [chatList, setChatList] = useState<Chat[]>([]);
  const [filter, setFilter] = useState('');
  const {t} = useTranslation();

  useEffect(() => {
    if (filter) {
      const lowerFilter = filter.toLowerCase();
      setChatList(chats.filter(chat =>  chat.chatName.toLowerCase().includes(lowerFilter)));
    } else {
      setChatList(chats)
    }
  }, [filter, chats]);



  return (
    <Box sx={sx}>
      <TextField fullWidth
                 sx={{padding: '10px 10px',
                 '& .MuiInputBase-root': {
                   borderRadius:'27px'
                 } }}
                 size={'small'}
                 placeholder={"Search"}
                 onChange={e => setFilter(e.target.value)}
                 InputProps={{
                   startAdornment:
                       (
                           <InputAdornment position="start" >
                             <SearchIcon/>
                           </InputAdornment>
                       )
                 }}
      />
      <List sx={{ width: '100%', p: 0,  height:{ md: `calc( 100vh - 270px )` }, overflow: {md: 'auto'}}}>
          <ListItem>
              <Typography variant={'h3'} sx={{fontSize: '1.15rem'}}>{t('Administration')}</Typography>
          </ListItem>
          {chatList.filter(c => c.type === 'SCHOOL_CHAT').map((chat) => {
              return <ChatListItem key={chat.chatId} avatar={'/icons/apple-icon.png'} isLast={true} chat={chat} onChatClick={e => onChatClick(chat)}/>
          })}
          <ListItem>
          <Typography variant={'h3'} sx={{fontSize: '1.15rem'}}>{t('Teachers')}</Typography>
         </ListItem>
          {chatList.filter(c => c.type === 'TEACHER_CHAT').map((chat, index) => {
              return <ChatListItem key={chat.chatId} isLast={chats.length - 1 === index} chat={chat} onChatClick={e => onChatClick(chat)}/>
          })}
      </List>
    </Box>
  )
}
