// material-ui
import {Avatar, Box, IconButton, Stack, Typography} from '@mui/material';

// project imports
import React from "react";
import {useAppSelector} from "../../../hooks";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {avatarProps} from "../../../utils/colours";
import {useNavigate} from "react-router";
import {StudentProfileSection} from "../Header/StudentProfileSection";

// assets

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const ChatHeader = () => {
    const navigate = useNavigate();
    const activeChat = useAppSelector(state => state.activeChat.activeChat);
    const chatIcon = activeChat && activeChat.type === 'SCHOOL_CHAT' ? '/icons/apple-icon.png' : undefined;

  return (
    <>
      {/* logo & toggler button */}

        <Stack sx={{width: '100%'}} direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
            <IconButton onClick={() => navigate('/chats')}><ArrowBackIcon sx={{color: '#7a7878', height: '30px', width: '30px'}}/></IconButton>

            <Avatar {...avatarProps(activeChat?.chatName ?? 'N A', 35, chatIcon)}/>
            <Typography variant={'h5'}>{activeChat?.chatName}</Typography>

            <Box sx={{flexGrow: 1}} />
            <StudentProfileSection variant={'simplified'}/>
        </Stack>


    </>
  );
};


export default ChatHeader;
