import {MainLayout} from "../layout/MainLayout";
import {CalendarPage} from "../pages/CalendarPage";
import {NotificationPage} from "../pages/NotificationPage";
import React from "react";
import {ChatPage} from "../pages/ChatPage";
import {GalleryPage} from "../pages/GalleryPage";
import {Stack, Typography} from "@mui/material";
import {Navigate} from "react-router";

const NotFoundPage = () => {
    return <Stack sx={{width: '100%', padding: '100px 0'}} alignItems={'center'}>
            <Typography variant={'h1'}>Sorry! That Page Doesn’t Seem to Exist.</Typography>
            </Stack>
};

export const MainRoutes = {
    element: <MainLayout/>,
    children: [
        {
            path: '/',
            label: 'calendar',
            element: <CalendarPage/>,
        },
        {
            path: '/example',
            children: [

            ]
        },
        {
            path: '/galleries',
            label: 'galleries',
            element: <GalleryPage/>
        },
        {
            path: '/notifications/announcements',
            label: 'announcements',
            element: <NotificationPage/>
        },
        {
            path: '/notifications/messages',
            element: <Navigate to="/chats" replace />
        },
        {
            path: '/chats',
            label: 'messages',
            element: <ChatPage/>
        },
        {
            path: '/chats/:chatId',
            label: 'messages',
            element: <ChatPage />
        },
        {
            path: '/chats/:chatId',
            label: 'messages',
            element: <ChatPage />
        },
        {
            path: '*',
            element: <NotFoundPage/>
        },
    ]
}
