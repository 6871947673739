import {styled, useTheme} from "@mui/material/styles";
import {Box, CssBaseline} from "@mui/material";
import {Outlet, useLocation} from 'react-router-dom';
import {AppBottomNavigation} from "./AppBottomNavigation";
import {HorizontalNavigation} from "../../ui-component/widget/HorizontalNavigation";
import {Notifications} from "./Notifications";
import {useAppSelector} from "../../hooks";
import {useEffect, useRef, useState} from "react";
import {MessageInput} from "../../ui-component/widget/chat/MessageInput";
import {DeviceComponent} from "../../ui-component/DeviceComponent";
import {useParams} from "react-router";
import ChatHeader from "./ChatHeader";
import Header from "./Header";


const Main = styled('main', {})(({theme}) => ({
    // @ts-ignore
    ...theme.typography.mainContent,

    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create(
        'margin',
        {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }
    )
}));


const calculateMainLayoutHeightGap = (isIphone: boolean, virtualKeyboardIsMaybeVisible: boolean, chatMessageFieldDiffSize: number) => {
    if (!virtualKeyboardIsMaybeVisible) {
        return  isIphone ? 145 : 120;
    }

    let gap =  160;

    if (isIphone) {
        if (window && window.visualViewport) {
            gap += (window.innerHeight - window.visualViewport.height)
        } else {
            gap += 400
        }
    }

    if (Number.isInteger(chatMessageFieldDiffSize) && chatMessageFieldDiffSize > 0 && chatMessageFieldDiffSize < 220) {
        gap += chatMessageFieldDiffSize
    }

    return gap;
}

export const MainLayout = () => {
    const theme = useTheme();

    const {
        virtualKeyboardIsMaybeVisible,
        textFieldIsFocused,
        chatMessageFieldDiffSize
    } = useAppSelector(state => state.siteEvents);

    const isIphone = Boolean(window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().includes('iphone'));
    const [withVirtualKeyboard, setWithVirtualKeyboard] = useState(false);
    const mainRef = useRef<HTMLElement>(null);
    const [mainScrollable, setMainScrollable] = useState(true);

    const [isChatPage, setChatPage] = useState(false);
    const activeChat = useAppSelector(state => state.activeChat.activeChat);

    const {pathname} = useLocation();

    const { chatId  } = useParams();

    useEffect(() => {
        setChatPage(!!pathname && pathname.startsWith('/chats'));
    }, [pathname]);

    useEffect(() => {
        if(mainRef.current) {
            if(withVirtualKeyboard) {
                setMainScrollable(mainRef.current?.scrollHeight > mainRef.current?.clientHeight);
            } else {
                setMainScrollable(true)
            }
        }
    }, [withVirtualKeyboard, mainRef.current?.clientHeight]);

    useEffect(() => {
        if(virtualKeyboardIsMaybeVisible && textFieldIsFocused) {
            setWithVirtualKeyboard(true);
        } else {
           setWithVirtualKeyboard(false)
        }

    }, [virtualKeyboardIsMaybeVisible, textFieldIsFocused]);

    useEffect(() => {
        if(withVirtualKeyboard) {
            window.scrollTo(0,0)
            if (mainRef.current) {
                mainRef.current.scrollTo(0, mainRef.current.scrollHeight);
            }
        }
    }, [withVirtualKeyboard]);
    return (
        <Box sx={{ backgroundColor: isChatPage ? 'white' : theme.palette.primary.light }}>
            <CssBaseline />
            {/* header */}

            <Notifications/>

            <Box
                sx={{
                    touchAction: withVirtualKeyboard ? 'none' : 'auto',
                    backgroundColor: 'white',
                    color:'white',
                    boxShadow: '0 8px 16px #0000000d',
                    position: 'relative',
                    zIndex: 2
                }}
            >
                    <Box sx={{display: 'flex', padding: '10px 16px', alignItems: 'center'}}>
                        {isChatPage && activeChat && <ChatHeader  />}
                        {(!isChatPage || !activeChat) && <Header/>}

                    </Box>

                <HorizontalNavigation/>
            </Box>

            <Main ref={mainRef} theme={theme} sx={{
                overflow: 'auto',
                wordBreak: 'break-word',
                height: { xs: `calc(100dvh - ${calculateMainLayoutHeightGap(isIphone, withVirtualKeyboard, chatMessageFieldDiffSize)}px)`, sm: 'calc(100dvh - 70px)'},
                marginBottom: {xs: `${withVirtualKeyboard ? 0 : 45}px`, sm: '0'},
                overscrollBehavior: withVirtualKeyboard ? 'none' : 'auto',
                //@ts-ignore
                touchAction: withVirtualKeyboard && !mainScrollable ? 'none' : 'auto'
            }}

                  style={{
                      padding: 0,
                     // height: `calc(100dvh - ${calculateMainLayoutHeightGap(isIphone, withVirtualKeyboard, chatMessageFieldDiffSize)}px)`
                }}>
                <Outlet context={{mainRef}} />

            </Main>
            <DeviceComponent type={"mobile"}>
                <Box component={'footer'} sx={{touchAction: withVirtualKeyboard ? 'none' : 'auto'}}>
                    {isChatPage && chatId && (
                        <MessageInput chatId={Number(chatId)} position={ withVirtualKeyboard ? 'relative' : 'fixed'}/>
                    )}

                    {!withVirtualKeyboard && ( <AppBottomNavigation/> )}
                </Box>
            </DeviceComponent>


        </Box>
    )
}
