import {combineReducers} from 'redux';
import {emailPreferenceReducer, studentDetailsReducer, studentsFetchChangeRequestsReducer} from "./feature/user";
import {configurationReducer} from "./configurationReducer";
import {calendarEventsReducer} from "./feature/calendar";
import {userChatMessagesReducerNew, userChatsReducer} from "./feature/chat";
import {notificationReducer} from "./notificationReducer";
import {studentEventsReducer, studentGalleriesReducer} from "./feature/event";
import {siteEventsReducer} from "./siteEventsReducer";
import {customizationReducer} from "./customizationReducer";
import {miscReducer} from "./miscReducer";
import {activeChatReducer} from "./activeChatReducer";


export const reducer = combineReducers({
    students: studentDetailsReducer,
    studentChangeRequests: studentsFetchChangeRequestsReducer,
    events: studentEventsReducer,
    galleries: studentGalleriesReducer,
    userEmailPreferences: emailPreferenceReducer,
    chats: userChatsReducer,
    chatMessagesNew: userChatMessagesReducerNew,
    calendarEvents: calendarEventsReducer,
    configuration: configurationReducer,
    siteEvents: siteEventsReducer,
    notification: notificationReducer,
    customize: customizationReducer,
    misc: miscReducer,
    activeChat: activeChatReducer,
})
