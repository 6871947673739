import {Chat} from "../types";
import {Reducer} from "redux";

export const SET_ACTIVE_CHAT = '@notification/SET_ACTIVE_CHAT';

export type ChatState = {
    activeChat: Chat | null,
}

export type SetAppIsActiveAction = {
    type:  typeof SET_ACTIVE_CHAT;
    value: Chat | null
}

export const initialState = {
    activeChat: null,
};


// ==============================|| MISC REDUCER ||============================== //

export const activeChatReducer: Reducer<ChatState, SetAppIsActiveAction> = (state = initialState, action: SetAppIsActiveAction ): ChatState => {

    switch (action.type) {
    case SET_ACTIVE_CHAT:
      return {
        ...state,
          activeChat: action.value
      };

    default:
      return state;
  }
};
