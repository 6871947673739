import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {useAppSelector} from "../../hooks";
import {selectStudentsDetails} from "../../store/feature/user";
import {IconCalendarEvent, IconMail, IconMessage, IconPhoto} from '@tabler/icons-react';
import {NotificationBadge} from "../../ui-component/NotificationBadge";

export const AppBottomNavigation = () => {

    const studentData = useAppSelector((state) => selectStudentsDetails(state));
    const {activeStudentId} = useAppSelector((state) => state.configuration);
    const [showChatBadge, setShowChatBadge] = useState(false);
    const [showGalleryBadge, setShowGalleryBadge] = useState(false);
    const [showEventsBadge, setShowEventsBadge] = useState(false);
    const [menuValue, setMenuValue] = useState(0);
    const isIphone = Boolean(window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().includes('iphone'));
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname && pathname.startsWith('/chats')) {
            setMenuValue(3);
        } else if (pathname === '/') {
            setMenuValue(0);
        } else if (pathname === '/notifications/announcements') {
            setMenuValue(2);
        }else if (pathname === '/galleries') {
            setMenuValue(1);
        }
    }, [pathname]);

    useEffect(() => {
        if(studentData && activeStudentId) {
            studentData.filter((s) => s.id === activeStudentId).forEach((s) => {
                setShowChatBadge(!s.chatRead);
                setShowEventsBadge(!s.eventsRead);
                setShowGalleryBadge(!s.galleriesRead);
            })
        }
    }, [studentData, activeStudentId]);

    const {t} = useTranslation();

    return (
        <BottomNavigation
            showLabels
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                padding:'5px 0 0 0',
                borderTop: '1px solid #dadada',
                height: isIphone ? '100px' : '75px',
                '& .MuiBottomNavigationAction-label': {
                    marginTop:'5px',
                    color: '#424242',
                    fontSize: '0.8rem'
                },
                '& .MuiBottomNavigationAction-label.Mui-selected': {
                    marginTop:'4px',
                    color: '#131313',
                    fontSize: '0.8rem',
                    fontWeight: 600
                },
                '& .Mui-selected': {
                },
                '& svg': {
                    color: '#888286',//theme.palette.primary.main
                    stroke: '#888286'//theme.palette.primary.main
                },
                '& .Mui-selected svg': {
                    color: '#4ba7f9',//theme.palette.primary.main
                    stroke: '#4ba7f9'//theme.palette.primary.main
                }
            }}
            value={menuValue}
        >
            <BottomNavigationAction
                component={Link}
                sx={{height: '60px', justifyContent: 'start', pt: '2px'}}
                to={'/'}
                label={t('menu.calendar')}
                icon={<IconCalendarEvent stroke={'1.3'}  size={'2.2em'} />}
            />
            <BottomNavigationAction
                component={Link}
                sx={{height: '75px', justifyContent: 'start', pt: '2px'}}
                to={'/galleries'}
                label={t('menu.galleries')}
                icon={<NotificationBadge visible={showGalleryBadge}><IconPhoto stroke={'1.3'}  size={'2.2em'}/></NotificationBadge>}
            />

            <BottomNavigationAction
                component={Link}
                sx={{height: '75px', justifyContent: 'start', pt: '2px'}}
                to={'/notifications/announcements'}
                label={t('menu.announcements')}
                icon={<NotificationBadge visible={showEventsBadge}><IconMail stroke={'1.3'} size={'2.2em'}/></NotificationBadge>}
            />
            <BottomNavigationAction
                component={Link}
                sx={{height: '75px', justifyContent: 'start', pt: '2px'}}
                to={'/chats'}
                label={t('menu.messages')}
                icon={<NotificationBadge visible={showChatBadge}><IconMessage  stroke={'1.3'}  size={'2.2em'}/></NotificationBadge>}
            />
        </BottomNavigation>
    )
}
