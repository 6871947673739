import {AvatarProps} from "@mui/material";

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function avatarProps(name: string, size = 40, image?: string): AvatarProps {
    if(!image) {
        return stringAvatar(name, size)
    }

    return {
        src: image,
        alt: "Compass",
        sx: {
            width: `${size}px`,
            height: `${size}px`,
            background: 'none'
        },
    }
}

export function stringAvatar(name: string, size = 40) {
    const bgColour = stringToColor(name);
    const textColor =  getTextColorForBackground(bgColour);
    const ns = name.split(' ');
    const avSymb = ns.length > 1 ? ns[0][0] + ns[1][0] : ns[0][0];
    return {
        sx: {
            bgcolor: stringToColor(name),
            color: textColor,
            width: `${size}px`,
            height: `${size}px`,
        },
        children: avSymb,
    };
}

function hexToRgb(hex: string) {
    // Remove the "#" if present
    hex = hex.replace("#", "");

    // Parse the r, g, b values from the hex code
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
}

// Function to calculate the relative luminance
function getLuminance(r: number, g: number, b: number) {
    // Normalize the RGB values to the range [0, 1]
    r /= 255;
    g /= 255;
    b /= 255;

    // Apply the gamma correction (sRGB)
    r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    // Calculate the luminance
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

// Function to determine whether black or white text will have better contrast
function getTextColorForBackground(hexColor: string) {
    const { r, g, b } = hexToRgb(hexColor);
    const luminance = getLuminance(r, g, b);

    // If luminance is more than 0.5, use black text; otherwise, use white text
    return luminance > 0.5 ? "#393838" : "#FFFFFF";
}
