import {Badge, Box, ClickAwayListener, Fade, IconButton, Paper, Popper, Typography} from "@mui/material";
import {IconSettings} from "@tabler/icons-react";
import React, {useEffect, useRef} from "react";
import {useTheme} from "@mui/material/styles";
import {useFetchStudentsDetails} from "../../../../hooks/fetch/useFetchStudentsDetails";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {StudentData} from "../../../../store/feature/user";
import {SET_ACTIVE_STUDENT_ID} from "../../../../store/configurationReducer";
import {StudentAvatar} from "./StudentAvatar";
import {SettingsChip} from "./SettingsChip";
import {UserMenu} from "./UserMenu";
import {NotificationBadge} from "../../../../ui-component/NotificationBadge";
import {SET_TITLE_VISIBLE} from "../../../../store/customizationReducer";
import {
    updateActiveUserIdInStorage,
} from "../../../../utils/auth";


type Props = {
    variant: 'full' | 'simplified'
}
export const StudentProfileSection = ({variant}: Props) => {

    const theme = useTheme();
    const students = useFetchStudentsDetails();

    const {activeStudentId} = useAppSelector(state => state.configuration)
    const [activeStudent, setActiveStudent] = React.useState<StudentData | undefined>(undefined);

    const [open, setOpen] = React.useState(false);
    const [selectMode, setSelectMode] = React.useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (Array.isArray(students) && activeStudentId) {
            setActiveStudent(students.filter(s => s.id === activeStudentId)[0]);
        }
    }, [activeStudentId, students]);


    const handleStudentClick = (studentId: number) => {
        dispatch({ type: SET_ACTIVE_STUDENT_ID, value: studentId});
        updateActiveUserIdInStorage(studentId)
        setSelectMode(false)
        dispatch({ type: SET_TITLE_VISIBLE, value: true});
    }

    const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
        setOpen((previousOpen) => !previousOpen);
    }

    const handleStudentsClick = () => {
        if (Array.isArray(students) && students.length > 1) {
            setSelectMode(true)
            dispatch({ type: SET_TITLE_VISIBLE, value: false});
        }
    }


    const anchorRef = useRef(null);
    return (
        <>
            <Box sx={{margin:'0 10px 0 0'}}>
                <Typography variant="h4">{activeStudent?.fullName.split(' ')[0]}</Typography>
            </Box>

            {variant === 'full' && (
                <>
                    <SettingsChip anchorRef={anchorRef} icon={
                        <>
                            {selectMode &&  Array.isArray(students) && students.map((student) => (
                                <IconButton key={student.id} onClick={() => handleStudentClick(student.id)}>
                                    <NotificationBadge top={10} right={3} visible={!student.chatRead || !student.eventsRead || !student.galleriesRead}>
                                        <StudentAvatar student={student} anchorRef={anchorRef} active={student.id === activeStudentId} />
                                    </NotificationBadge>
                                </IconButton>
                            ))}

                            {!selectMode && Array.isArray(students) && activeStudent && (
                                <>
                                    <IconButton key={activeStudent.id} onClick={() => handleStudentsClick()}>
                                        <NotificationBadge sx={{zIndex: 10}} top={10} right={3} visible={!activeStudent.chatRead || !activeStudent.eventsRead || !activeStudent.galleriesRead}>
                                            <StudentAvatar student={activeStudent} anchorRef={anchorRef} active={true} />
                                        </NotificationBadge>

                                        {students.filter((s) => s.id !== activeStudentId).map((student, index) => (
                                            <NotificationBadge key={index} top={10} right={3} visible={!student.chatRead || !student.eventsRead || !student.galleriesRead} sx={{
                                                marginLeft: '-23px',
                                                zIndex: (9 - index)
                                            }} >
                                                <StudentAvatar student={student} anchorRef={anchorRef} active={false} />
                                            </NotificationBadge>
                                        ))}
                                    </IconButton>
                                </>
                            )}

                            <IconButton onClick={handleSettingsClick}>
                                <IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />
                            </IconButton>
                        </>
                    } />

                    {open && (
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <Popper
                                placement="bottom-end"
                                sx={{
                                    zIndex: 2
                                }}
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                disablePortal={true}
                                transition
                                popperOptions={{
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [50, 10]
                                            }
                                        }
                                    ]
                                }}
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps}>
                                        <Paper>
                                            <UserMenu />
                                        </Paper>

                                    </Fade>
                                )}
                            </Popper>
                        </ClickAwayListener>
                    )}
                </>
            )}
        </>

    )
}
